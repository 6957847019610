<template>
  <component :is="mainLayoutLoader" v-bind="propsDown"></component>
</template>

<script>
import Vue from "vue";
import { urlObservable } from "./observables/url";
import { tokenObservable } from "./observables/token";

export default {
  data() {
    return {
      mapboxDiv: document.createElement("div"),
      loaded: false
    };
  },
  computed: {
    map() {
      return this.$mapbox;
    },
    mainLayoutLoader() {
      if (this.loaded) {
        return () => import("./components/Main");
      }
      return null;
    },
    propsDown() {
      return Object.assign({}, this.$props, { mapboxDiv: this.mapboxDiv });
    }
  },
  props: {
    offsetHeight: {
      type: String,
      default: () => "0px"
    },
    locale: {
      type: String
    },
    cdUnidade: {
      type: Number,
      default: () => parseInt(process.env.VUE_APP_DEFAULT_CD_UNIDADE)
    },
    unidades: {
      type: String,
      default: () => "999"
    },
    cdUsuario: {
      type: String,
      default: () => "douglas.silva"
    },
    token: {
      type: String,
      default: () =>
        "eyJhbGciOiJIUzUxMiIsInppcCI6IkRFRiJ9.eNo8jEEOgjAURK9CukYDUZCyBhM3xMRg3JEGa_Mj_DZtARPjkTyFF_PXhcuZ92aeTC8oLSuZsnoZhb13vdamu8qZxUziDFbjKNGT0TZVvT80dUVkQqCKcx4zQOcF9pIMN9zShKjrtfllZQRFFGNIZ8DPu4fJRRcxg7TREVB4D2QMAtUkVLAkrtoTVR7CqMi3SUJcK0CCMyCEh7X5T-XDsDLNeMY3WV7sXl8AAAD__w.5YX_LDBZs2cUaBnySgJB3qFxxZ2jcNeNMgwlgtKvGZT2htDAWYpZYLkjTxdNuwrabKeCsBgMUwkd2OXoj_cgdA"
    },
    urlSgp: {
      type: String,
      default: () => "https://homologation-sierra-sgp-api.saas-solinftec.com"
    },
    /* Defini qual grupo de servidor este cliente pertence 
    0 - padrao
    1 - estados unidos
    2 - cana
    3 - graos / perenes / outros
    */
    grupo: {
      type: Number,
      default: 0
    },
    homologacaoOuDev: {
      type: String,
      default: "F"
    }
  },
  created() {
    this.setUrls();
    this.loaded = false;
    console.log(urlObservable);
    /* global mapboxgl */
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;
    this.mapboxDiv.id = "map";
    const mapbox = new mapboxgl.Map({
      container: this.mapboxDiv, // container id
      style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
      center: [-51.8, -10.7], // starting position [lng, lat]
      zoom: 4, // starting zoom
      transformRequest: (url, resourceType) => {
        if (url.includes("desc_talhao/unidade")) {
          console.log(resourceType);
          return {
            url: url,
            headers: { "X-Auth-Token": this.token }
          };
        }
      }
    });
    Vue.prototype.$mapbox = mapbox;
    this.map.on("load", this.onLoad);
    this.map.on("error", this.onError);
  },
  deactivated() {
    this.loaded = false;
    /* Clean up and release all internal resources associated with this map. */
    this.map.remove();
    delete Vue.prototype.$mapbox;
  },
  methods: {
    /* Seta as urls de acordo com o grupo. Development continua da forma antiga (sem sufixo -{grupo}) */
    setUrls() {
      const { grupo } = this;
      tokenObservable.token = this.token;
      urlObservable.tileServer =
        process.env["VUE_APP_TILE_SERVER_" + grupo] ||
        process.env.VUE_APP_TILE_SERVER_0;
      urlObservable.backServer =
        process.env["VUE_APP_BACK_SERVER_" + grupo] ||
        process.env.VUE_APP_BACK_SERVER_0;
      urlObservable.backServerInterpolation =
        process.env["VUE_APP_BACK_SERVER_INTERPOLATION_" + grupo] ||
        process.env.VUE_APP_BACK_SERVER_INTERPOLATION_0;
      urlObservable.backServerInterpolationOnDemand =
        process.env["VUE_APP_BACK_SERVER_INTERPOLATION_ON_DEMAND_" + grupo] ||
        process.env.VUE_APP_BACK_SERVER_INTERPOLATION_ON_DEMAND_0;
    },
    onLoad() {
      this.loaded = true;
    },
    onError(error) {
      console.error(error);
    }
  }
};
</script>
<style lang="scss">
@import "./styles/global.scss";
@import "./plugins/colors.css";
$material-design-icons-font-directory-path: "~material-design-icons-iconfont/dist/fonts/";
@import "~material-design-icons-iconfont/src/material-design-icons";
@import "~vuetify/dist/vuetify.min.css";
@import "~mapbox-gl/dist/mapbox-gl.css";
</style>
